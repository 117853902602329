class ImgDN{
    constructor( imgProvider ){
        this.provider = imgProvider;
    };
    getAssetUrl(id, transformation={}){
        return this.provider.getAssetUrl(id, transformation);
    }
    getUploadImgParams(imgType, additionalParams={}){
        return this.provider.getUploadImgParams(imgType, additionalParams);
    }
    async get(id, opt, signal=null){
        try{
            return this.provider.get(id, opt, signal);
        }catch(err){
            throw err
        }
    };
    async getAllImageIdInFolder(folder, maxResult=10, exp={}, signal=null){
        try{
            return this.provider.getAllImageIdInFolder(folder, maxResult, exp, signal);
        }catch(err){
            throw err
        }
    }
    async upload(file, params, signal=null){
        try{
            return this.provider.upload(file, params, signal);
        }catch(err){
            throw err
        }
    };
    async remove(id, signal=null){
        try{
            return this.provider.remove(id, signal);
        }catch(err){
            throw err
        }
    };
}

export default ImgDN 