import { Typography, Divider } from "@material-ui/core";
import {Menu, MenuItem} from '@mui/material';
import { Link } from "react-router-dom";

import { textTransform } from "../../aux/aux";

import './hamburguerMenu.css'

const HamburguerMenu = ({ anchorEl, onClose, transformOrigin={}, anchorOrigin={}, paperProps={}, onClick=null, ...props }) => {
    const isMenuOpen =  Boolean(anchorEl !== null);
    return (
        <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={onClose}
            onClick={onClick || onClose}
            PaperProps={paperProps}
            transformOrigin={transformOrigin}
            anchorOrigin={anchorOrigin}
        >
            {props.children}
        </Menu>
    )
}

export default HamburguerMenu