import { useEffect, useState } from "react";
import ImgDN from "../cdn/imgDN";
import cloudinary from "../cdn/providers/cloudinary";
import { Box } from "@material-ui/core";
import { useRef } from "react";

export default function useDynamicSVGImportFromURL (uri=null, cdnProvider=cloudinary, ref=undefined, style, className) {
    const [svgText, _setSvgText] = useState(null);
    const [SvgComponent, _setSvgComponent] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const fileNameRef = useRef(null);

    const setSvgText = async(uri, cdnProvider=cloudinary, signal=null) => {
        try{
            setIsLoading(true)
            if(uri){
                const assetProvider = new ImgDN(cdnProvider);
                const response = await assetProvider.get(uri, {returnBlob:true}, signal);
                const svgText = await response.text();
                _setSvgText(svgText)
            }
            setError(null);
        }catch(error){
            if(!signal || !signal.aborted){
                setError(error)
            }
        }finally{
            if(!signal || !signal.aborted){
                setIsLoading(false)
            }
        }

    }

    const setSvgComponent = (svgText, fileName, props) => {
        const updateNamespaceWithPrefix = (svgDoc, fileName, divider='-') => {
            // Append a prefix to each id or class in the svg elements
            const prefix = fileName + divider;
            const elements = svgDoc.querySelectorAll('*');
            elements.forEach((element, index) => {
                if(element.tagName === 'style'){
                    const styleMatch = element.textContent.matchAll(/\.[^{]+/g) || [];
                    let newTextContent = '';
                    let lastNotIncludedIndex = 0;
                    [...styleMatch].forEach( style => {
                        const index = style.index;
                        const oldStyleName = style[0];
                        const newStyleName = oldStyleName.substring(0,1) + prefix + oldStyleName.substring(1);
                        const previousStyleContent = element.textContent.substring(lastNotIncludedIndex,index);
                        newTextContent += previousStyleContent + newStyleName;
                        lastNotIncludedIndex += previousStyleContent.length + oldStyleName.length;
                    })
                    element.textContent = newTextContent + element.textContent.substring(lastNotIncludedIndex)
                }
                if(element.id){
                    element.id = prefix + element.id;
                } 
                if(element.className && element.className.baseVal){
                    element.className.baseVal = prefix + element.className.baseVal
                } 
            })
            return svgDoc;
        }
        const setPropsInHTMLElem = (elem, props) => {
            const {style, className} = props;
            elem.class = className;
            elem.setAttribute('class',className)
            Object.entries(style).forEach((key, value) => {
                elem.style[key] = value;
            })
            return elem;
        }
        const parser = new DOMParser();
        let svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
        svgDoc = updateNamespaceWithPrefix(svgDoc, fileName);
        let svgElem = svgDoc.documentElement;
        const {
            ref,
            ...htmlProps
        } = props;
        svgElem = setPropsInHTMLElem(svgElem, htmlProps)
        const serializer = new XMLSerializer();
        _setSvgComponent(<Box ref={ref} component={'div'} dangerouslySetInnerHTML={{__html:serializer.serializeToString(svgElem)}} />)
    }

    const getFileNameFromUri = (uri) => {
        let fileName;
        if(uri){
            const fileNameWithExt = uri.match(/[^\/]+$/)[0];
            fileName = fileNameWithExt.split('.')[0];
        }
        return fileName;
    }
    
    useEffect(() => {
        if(svgText && fileNameRef.current){
            const props = {style, className, ref}
            setSvgComponent(svgText, fileNameRef.current, props);
        }
    },[svgText, style, className, ref])

    useEffect(() => {
        const abortController = new AbortController();
        fileNameRef.current = getFileNameFromUri(uri);
        setSvgText(uri, cdnProvider, abortController.signal);
        return(() => { abortController.abort()})
    },[uri, cdnProvider])

    return {error, isLoading, SvgComponent, setSvgComponent: setSvgText}
}

