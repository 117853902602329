import { useEffect, useState } from 'react';
import { Chip, Typography, hexToRgb } from '@material-ui/core';
import { useTheme } from '@material-ui/styles'
import { isValidDate, rgbToRgbaOrUndefined, textTransform } from '../../aux/aux';
import { isProductNew } from '../../aux/productHelpers';
import { useTranslation } from 'react-i18next';
import CustomChip from '../CustomChip/CustomChip';

const ConsumableDateChip = ({ consumableDateStatus, releaseDate, isConsumable, size, isContrast=false }) => {
    const { t } = useTranslation('common', { keyPrefix: 'consumableDateChip' })
    const theme = useTheme()
    const [labelKey, setLabelKey] = useState(null);
    const [Icon, setIcon] = useState(null);
    const colors = {
        default: isContrast ? theme.palette.text.contrast : undefined
    }
    const styles = {
        background: isContrast ? 
            {
                backgroundColor: 'rgba(0,0,0,0.2)'
            }
            :
            {
                backgroundColor: theme.palette.common.white
            },
        icon: {
            paddingLeft:'4px'
        }
    }
    useEffect(() => {
        if(consumableDateStatus){
            let labelKey = null;
            let emojiKey = null;
            switch(consumableDateStatus){
                case 'noDate':
                    if(isConsumable){
                        labelKey = 'preorder';
                        emojiKey = 'common:symbolRocket'
                    }else{
                        labelKey = 'comingSoon';
                        emojiKey = 'common:symbolClock'
                    }
                    break;
                case 'noReleased':
                    if(isConsumable){
                        labelKey = 'preorder';
                        emojiKey = 'common:symbolRocket'
                    }else{
                        labelKey = 'new';
                        emojiKey = 'common:symbolStars'
                    }
                    break;
                case 'released':
                    const isNew = isProductNew(releaseDate);
                    if(isNew){
                        labelKey = 'new';
                        emojiKey = 'common:symbolStars'
                    }
                    break;
            }
            setIcon(emojiKey ? <Typography variant='body2' style={styles.icon}>{t(emojiKey)}</Typography> : null)
            setLabelKey(labelKey)
        }
    },[consumableDateStatus, isConsumable, releaseDate])
    return (
        labelKey ?
            <CustomChip 
                size={size ||'small'}
                variant={'default'}
                label={t(labelKey)}
                color={colors[labelKey] || colors.default}
                style={styles.background}
                icon={Icon}
            />
            :
            null
    )
}

export default ConsumableDateChip