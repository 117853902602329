import { cloneElement } from 'react';
import { useEffect, useRef, useState } from 'react';

export default function useDynamicSVGImport(svgFileName, ref, style, className){
    // const svgDir =  require.context('!@svgr/webpack!../img/', true, /^.+\.svg$/);
    const [isLoading, setIsLoading] = useState(false);
    // const [path, setPath] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [error, setError] = useState(null);
    const [ImportedSvg, setImportedSvg] = useState(null);
    const [SvgComponent, _setSvgComponent] = useState(null);

    // const setSvgFilePath = (fileName) => {
    //     if(fileName){
    //         setPath(`./${fileName}.svg`);
    //     }
    // }

    // useEffect(() => {
        // setSvgFilePath(svgFileName);
    // },[svgFileName])

    // const setSvgFileName = (fileName) => {
    //     if(fileName){
    //         setFileName(fileName)
    //     }
    // }

    // useEffect(() => {
    //     setSvgFileName(svgFileName);
    // },[svgFileName])

    const setSvgComponent = async(fileName) => {
        try{
            if(fileName){
                setIsLoading(true);
                // ImportedSvgRef.current = (await svgDir(path)).default;
                // if(onComplete){
                //     onComplete(path, ImportedSvgRef.current);
                // }
                const ImportedSvg = (await import(
                        /* webpackPreload: true,
                        webpackChunkName: "SvgIcons" */
                    '!@svgr/webpack!../img/'+fileName+'.svg'
                )).default;
                setImportedSvg(ImportedSvg);
                setError(null)
            }
        }catch(error){
            setError(error);
        }finally{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(ImportedSvg){
            const props = {style, className};
            _setSvgComponent(cloneElement(ImportedSvg, {...props, ref}))
        }
    },[ImportedSvg, ref, className, style])

    useEffect(() => {
        setSvgComponent(svgFileName)
    },[svgFileName])    

    return {error, loading: isLoading, setSvgPath:setSvgComponent, SvgComponent }
}
