import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material'
import { getFromSafeObject, isNotEmptyObject, textTransform, textTruncate } from '../../aux/aux';
import './basicProductConsumptionOptionItem.css';
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles'; 
import { useTranslation } from 'react-i18next';
import { WarningAmber } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import IconLabel from '../IconLabel/IconLabel';

const DEFAULT_STYLES_MAP = {
    selected:{}
}
const BasicProductConsumptionOptionItem = ({ productConsumptionOption, disabled, isSelected, isBestPrice, isRecommended, isUnique, onSelected, warningMessage='', stylesMap=DEFAULT_STYLES_MAP, disableOnClick: disabledOnClick=false, ...props }) => {
    const MAX_NAME_CHAR = 23;
    const { t } = useTranslation('common');
    const theme = useTheme()
    const [imgURL, setImgURL] = useState(null);
    const styles = {
        mainContainerWrapper:{
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
        },
        mainContainer: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
            ...(
                isSelected && !disabled ?
                    {
                        boxShadow: theme.shadows[2],
                        border: `0.5px solid ${theme.palette.primary.main}`,
                        ...stylesMap.selected,
                    } 
                    :
                    {
                        boxShadow: theme.shadows[1],
                    }
            )
        },
        name: {
            // fontWeight: 'bold'   
        },
        disabledMessageContainer:{
            color: theme.palette.grey[500]
        },
        icon:{
            display:'block',
            fontSize: theme.typography.caption.fontSize
        }
    }
    const handleOnClick = (e) => {
        e.preventDefault();
        onSelected(productConsumptionOption)
    }

    useEffect(() => {
        let imgURL;
        const imgIcon = getFromSafeObject(productConsumptionOption, 'imageDetails.main');
        if(isNotEmptyObject(imgIcon)){
            const imgPublicId = imgIcon.publicId;
            const assetProvider = new ImgDN(cloudinary);
            imgURL = assetProvider.getAssetUrl(imgPublicId);
        }else{
            imgURL = null;
        }
        setImgURL(imgURL)
    },[productConsumptionOption])

    return (
        <div className='basic-product-consumption-option-item-wrapper'>
            <div style={styles.mainContainerWrapper}>
                <div className={`basic-product-consumption-option-item-main-container${disabled ? ' disabled' : ''}${disabledOnClick ? ' disabled-on-click' : ''}`} onClick={disabled || disabledOnClick ? null : handleOnClick} style={styles.mainContainer}>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <div className='basic-product-consumption-option-item-img-container'>
                            {imgURL ? 
                                <img className='basic-product-consumption-option-item-img' alt='product-img' src={imgURL}/>
                                :
                                <Skeleton className='basic-product-consumption-option-item-img-skeleton' variant="rectangular"/>
                            }
                        </div>
                        <div className='basic-product-consumption-option-item-details-container'>
                            <div className='basic-product-consumption-option-item-details-description-container'>
                                {isNotEmptyObject(productConsumptionOption) ?
                                    <Typography variant="body2" style={styles.name}>
                                        {textTruncate(getFromSafeObject(productConsumptionOption, 'description.nameTranslated'), MAX_NAME_CHAR)}
                                    </Typography>
                                    :
                                    <Skeleton variant='text' width={'50%'}/>
                                }
                            </div>
                            {props.ConfigSelectorComponent}
                        </div>
                    </div>
                    {props.children}
                    {!isUnique && (isBestPrice || isRecommended) ?
                        <div className='basic-product-consumption-option-item-icons-container'>
                            {isBestPrice ? 
                                <IconLabel Icon={LocalOfferIcon} color={theme.palette.secondary.main} label={textTransform('title', t('bestPrice'))}/>
                                :
                                null
                            }
                            {isRecommended ? 
                                <IconLabel Icon={StarIcon} color={theme.palette.primary.main} label={textTransform('title',t('recommended'))}/>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            {warningMessage ?
                <div className='basic-product-consumption-option-item-purchasability-details-container' style={styles.disabledMessageContainer}>
                    <WarningAmber style={styles.icon}/>
                    <Typography variant='caption'>
                        {warningMessage}
                    </Typography>
                </div>
                :
                null
            }
        </div>
    )
}

export default BasicProductConsumptionOptionItem