import { Chip, hexToRgb } from '@material-ui/core'
import { isRgbColor, rgbToRgbaOrUndefined } from '../../aux/aux'

/**
 * 
 * @param {*} props { color, style , variant ...props}
 *  color: hex, rgb notation 
 * @returns 
 */
const CustomChip = ({ color, variant, style={}, ...props }) => {
    const getVariantStyle = (variant) => {
        let style;
        switch(variant){
            case 'text':
                style = {
                    border: 'none',
                    backgroundColor: 'transparent'
                }
                break;
            case 'outlined':
                style = {
                    border: 'solid 1px',
                    backgroundColor: 'transparent',
                    ...color ? {borderColor: color}: {}
                }
                break;
            case 'default':
                style = {
                    border: 'none',
                    ...color ? {backgroundColor: rgbToRgbaOrUndefined(isRgbColor(color) ? color : hexToRgb(color), 0.1)}: {}
                }
                break;
            default:
                style = color ? {
                        border: 'solid 1px',
                        borderColor: color,
                        backgroundColor: rgbToRgbaOrUndefined(isRgbColor(color) ? color : hexToRgb(color), 0.1),
                    }
                    :
                    {}
        }
        return style
    }
    const styles = {
       variant: { color: color, ...getVariantStyle(variant) }
    }
    return (
        <Chip
            style={{...styles.variant, ...style}}
            {...props}
        />
    )
}

export default CustomChip