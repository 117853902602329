import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/styles';
import { Grid, useMediaQuery, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import ContentSliderDots from '../ContentSliderDots/ContentSliderDots';
import ContentResponsiveSliderItem from '../ContentResponsiveSliderItem/ContentResponsiveSliderItem';
import { getHandSelectedProduct } from '../../services/staticContentServices';
import { getContentProductInfo } from '../../services/visitorServices';
import { getFromSafeObject, isNotEmptyObject } from '../../aux/aux';
import { useUpdateUserFavoriteProductCtx } from '../../customHooks/useUpdateUserFavoriteProductCtx';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './futureContentSection.css'
import SliderBasic from '../SliderBasic/SliderBasic';
import Carrousel from '../Carrousel/Carrousel';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';

const FutureContentSection = ({ 
    user={user},
    selectedContentArray=null, 
    title=undefined,
    subtitle=undefined,
    wrapperProps={},
    noVerticalPadding=false, 
}) => {
    const { requestUserFavoriteProductArray } = useUpdateUserFavoriteProductCtx();
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const matchMobile = useMediaQuery('(max-width:680px)');
    const localeCtx = useLocaleCtx();
    const SLIDES_TO_SCROLL = 1;
    const STEPS_SIZE = 1;
    const INITIAL_SLIDE_INDEX = 0;
    const [numSteps, setNumSteps] = useState(null);
    const [currentStep, setCurrentStep] = useState(INITIAL_SLIDE_INDEX);
    const [futureContentArray, setFutureContentArray] = useState(selectedContentArray);
    const [futureContentInfoMap, setFutureContentInfoMap] = useState(null);
    const maxSlidesWithoutSlider = matchMobile ? 1 : matchDesktop ? futureContentInfoMap != null ? Object.values(futureContentInfoMap).length : 0 : 2;
    // const [maxSlidesWithoutSlider, setMaxSlidesWithoutSlider] = useState(null);
    const slideShowRef = useRef(null);

    const styles = {
        title: {
            textAling: 'center',
            fontWeight: 'bold'
        },
        subtitle: {
            fontWeight: 'bold'
        }
    }
    const onChangeSlide = ( oldStep, newStepIndex) => {
        setCurrentStep(newStepIndex);
    }

    const onGoToStep = (selectedStep) => {
        if(slideShowRef.current){
            slideShowRef.current.slickGoTo(selectedStep)
        }
    }

    const getNumSlides = () => {
        let numSlides;
        if(futureContentInfoMap != null){
            numSlides = Object.values(futureContentInfoMap).length;
        }else if(futureContentArray != null){
            numSlides = futureContentArray.length;
        }else{
            numSlides = 0;
        }
        return numSlides;
    }

    const getFutureContentArray = async(localeId, abortSignal) => {
        try{
            const opt = { 
                localeId,
                productTypeName: 'content',
                filteredBy:['noAccessible', 'noReleased']
            };
            const res = await getHandSelectedProduct( opt, 0, abortSignal);
            if(!abortSignal.aborted){
                setFutureContentArray(res.data);
            }
        }catch(error){
            if(!abortSignal.aborted){
                setFutureContentArray(null);
            }
            // silencing error
        }
    }

    const getFutureContentInfo = async(localeId, abortSignal) => {
        try{
            const contentProductIdArray = futureContentArray.map( futureContent => { return futureContent.productId });
            const opt = {
                localeId,
                asMap:true,
            }
            const res = await getContentProductInfo(contentProductIdArray, opt, 0, abortSignal);
            const {
                contentProductInfoCollection,
                retrievalDetails
            } = res.data;
            if(!abortSignal.aborted){
                setFutureContentInfoMap(contentProductInfoCollection)
            }
        }catch(error){
            if(!abortSignal.aborted){
                setFutureContentInfoMap(null)
            }
            // silencing error
        }
    }

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1.99,
        slidesToScroll: SLIDES_TO_SCROLL,
        initialSlide: INITIAL_SLIDE_INDEX,
        beforeChange: onChangeSlide,
        responsive: [
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1.99,
                },
                breakpoint: 430,
                settings:{
                    slidesToShow: 1
                }
            }
        ]
    }

    useEffect(() => {
        setFutureContentArray(selectedContentArray)
    },[selectedContentArray])

    useEffect(() => {
        if(!(futureContentArray instanceof Array)){
            const abortController  = new AbortController();
            getFutureContentArray(localeCtx.localeId, abortController.signal);
            return(() => {
                abortController.abort();
            })
        }
    },[localeCtx.localeId])

    useEffect(() => {
        if(futureContentArray instanceof Array && futureContentArray.length > 0){
            const abortController = new AbortController();
            getFutureContentInfo(localeCtx.localeId, abortController.signal);
            return(() => {
                abortController.abort();
            })
        }
    },[futureContentArray, localeCtx.localeId])

    useEffect(() => {
        if(user && futureContentArray instanceof Array && futureContentArray.length > 0){
            const abortController = new AbortController();
            const productIdentifierArray = futureContentArray.map(futureContent => ({ productId:futureContent.productId }))
            requestUserFavoriteProductArray(productIdentifierArray, abortController.signal)
            return(() => {
                abortController.abort();
            })
        }
    },[user, futureContentArray])

    useEffect(() => {
        let numSteps = 0
        if(futureContentInfoMap && Object.values(futureContentInfoMap).length > 0){
            numSteps = Object.values(futureContentInfoMap).length / STEPS_SIZE; 
        }
        setNumSteps(numSteps);
    },[futureContentInfoMap])


    return (
        futureContentArray instanceof Array && futureContentArray.length > 0 ?
            <div className='future-content-section-wrapper' {...wrapperProps}>
                <div className={`future-content-section-main-container${noVerticalPadding ? ' no-vertical-padding' : ''}`}>
                    {title &&
                        <Typography 
                            variant={matchMobile ? 'h5' : 'h4'} 
                            style={styles.title}
                            className='future-content-section-title'
                        >
                            {title}
                        </Typography>
                    }
                    {subtitle &&
                        <Typography 
                            variant={matchMobile ? 'h6' : 'h5'} 
                            style={styles.subtitle}
                            className='future-content-section-title'
                        >
                            {subtitle}
                        </Typography>
                    }
                    <div className='future-content-section-carrousel-container'>
                        <Carrousel
                            Slider={SliderBasic}
                            sliderProps={{ settings, className:'future-content-section-carrousel-slider-inner-container' }}
                            hideSlider={getNumSlides() <= maxSlidesWithoutSlider || matchDesktop}
                            noSliderClassName={'future-content-section-carrousel-no-slider-inner-container'}
                        >
                            {futureContentArray.map((futureContentProduct, index) => {
                                const content = futureContentInfoMap == null ? {} : futureContentInfoMap[futureContentProduct.productId] != null ? futureContentInfoMap[futureContentProduct.productId] : null; 
                                if(isNotEmptyObject(content)){
                                    content.details['selected'] = futureContentProduct
                                }
                                return(
                                    content !== null ?
                                            <Grid item key={index} className={ matchDesktop ? 'future-content-section-carrousel-no-slider-item' : getNumSlides() <= maxSlidesWithoutSlider ? 'future-content-section-carrousel-no-slider-item' : 'future-content-section-carrousel-item' }>
                                                <ContentResponsiveSliderItem content={content}/>
                                            </Grid>    
                                        :
                                            null
                                )
                            })}
                        </Carrousel>
                    </div>
                </div>
            </div>
            :
            null
    )
}

export default FutureContentSection