import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import { useState } from "react";
//import YouTube  from "@material-ui/icons/YouTube";
//import Email  from "@material-ui/icons/Email";
//import FacebookIcon from '@material-ui/icons/Facebook';
import FacebookIcon from '../../img/logo-facebook.svg';
import Youtube from '../../img/logo-youtube.svg';
import Instagram from '../../img/logo-instagram.svg';
import Email from "../../img/logo-email.svg"
import { useTheme } from "@material-ui/styles";
import "./smallFooter.css"

const SmallFooter = ({onLightBackground=false, darkMode=false, hideSocialLinks=false, className=undefined, style=undefined}) => {
    const year = new Date().getFullYear();
    const ICON_HEIGHT_PX = 25;
    const theme = useTheme();
    const [focusOnMap, setFocusOnMap] = useState({'facebook':false, 'youtube':false, 'email':false, 'instagram':false });
    const styles = {
        brandContainer:{
            color: onLightBackground ? theme.palette.text.disabled : darkMode ? theme.palette.grey[400] : theme.palette.common.black
        },
        icon:{
            height: `${ICON_HEIGHT_PX}px`,
            display: 'block',
        },
        onUnfocusIcon: {
            fill: onLightBackground ? theme.palette.text.disabled : darkMode ? theme.palette.primary.light : theme.palette.common.black,
        },
        onFocusIcon: {
            fill: onLightBackground ? theme.palette.text.black : darkMode ? theme.palette.primary.lighter : theme.palette.primary.main
        },
        // facebookIcon:{
        //     fill: onLightBackground ? theme.palette.text.disabled : theme.palette.common.black //onLightBackground ? theme.palette.text.primary : theme.palette.common.white, //"#4267B2";
        // },
        // youtubeIcon:{
        //     fill: onLightBackground ? theme.palette.text.disabled : theme.palette.common.black //onLightBackground ? theme.palette.text.primary : theme.palette.common.white, //'#FF3333';
        // },
        // emailIcon:{
        //     fill: onLightBackground ? theme.palette.text.disabled : theme.palette.common.black // onLightBackground ? theme.palette.text.primary : theme.palette.common.white,
        // }
    }
    const onClick = (e, type) => {
        if(type=="text"){
            e.target.style.textDecoration="none";
        } else {

                // e.currentTarget.setAttribute("fill", onFocusColor)
        }
    }

    const onMouseEnter = (e, type) => {
        if(type=="text"){
            e.target.style.textDecoration= "underline";
        } else {
            setFocusOnMap(prev => ({...prev, [type]:true}))
        }
    }

    const onMouseLeave = (e, type) => {
        if(type=="text"){
            e.target.style.textDecoration="none";
        } else {
            setFocusOnMap(prev => ({...prev, [type]:false}))
        }
    }
    return (
        <div className={`small-footer-wrapper${className ? ` ${className}` : ''}`} style={style}>
            <div className="small-footer-main-container">
                <div className="small-footer-brand-name-container" style={styles.brandContainer}> 
                    <Typography variant= "body2">
                        &copy; {`${year} QX Institute`}
                    </Typography>
                </div>
                {!hideSocialLinks ?
                    <div className="small-footer-social-container"> 
                        <div>
                            <IconButton 
                                size="small" aria-label="email"
                                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target="_newtab"
                                onMouseEnter= {(e)=>{onMouseEnter(e, "email")}}
                                onMouseLeave= {(e)=>{onMouseLeave(e, "email")}}
                                onClick= {(e)=>{onClick(e, "email")}}
                            >
                                <Email style={{...styles.icon, ...focusOnMap.email ? styles.onFocusIcon :styles.onUnfocusIcon}}/>
                            </IconButton>
                        </div>
                        <div>
                            <IconButton size="small"  aria-label="youtube channel" 
                                href="https://www.youtube.com/@QxInstitute"
                                target="_newtab"
                                onClick= {(e)=>{onClick(e, "youtube")}} 
                                onMouseEnter= {(e)=>onMouseEnter(e, "youtube")}
                                onMouseLeave= {(e)=>onMouseLeave(e, "youtube")}
                            >
                                <Youtube style={{...styles.icon, ...focusOnMap.youtube ? styles.onFocusIcon :styles.onUnfocusIcon}} />
                                {/*<YouTube color="primary"/> */}
                            </IconButton>
                        </div>
                        <div>
                            <IconButton size="small" aria-label="facebook" 
                                component="a"
                                href="https://www.facebook.com/qxinstitute"
                                target="_newtab"
                                onMouseEnter= {(e)=>onMouseEnter(e, "facebook")}
                                onMouseLeave= {(e)=>onMouseLeave(e, "facebook")}
                                onClick= {(e)=>{onClick(e, "facebook")}}
                            >
                                <FacebookIcon style={{...styles.icon, ...(focusOnMap.facebook ? styles.onFocusIcon :styles.onUnfocusIcon)}}/>
                                {/* <FacebookIcon color="primary"/> */}
                            </IconButton>
                        </div>
                        <div>
                            <IconButton size="small" aria-label="instagram" 
                                component="a"
                                href="https://www.instagram.com/qxinstitute"
                                target="_newtab"
                                onMouseEnter= {(e)=>onMouseEnter(e, "instagram")}
                                onMouseLeave= {(e)=>onMouseLeave(e, "instagram")}
                                onClick= {(e)=>{onClick(e, "instagram")}}
                            >
                                <Instagram style={{...styles.icon, ...(focusOnMap.instagram ? styles.onFocusIcon :styles.onUnfocusIcon)}}/>
                                {/* <FacebookIcon color="primary"/> */}
                            </IconButton>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default SmallFooter
