import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { isValidDate, textTransform } from '../../aux/aux';
import './consumableDateWidget.css'

const DEFAULT_LABEL_PROPS = {};
const DEFAULT_DATE_PROPS = {};
const ConsumableDateWidget = ({ consumableDateStatus, releaseDate, labelProps=DEFAULT_LABEL_PROPS, dateProps=DEFAULT_DATE_PROPS, ...props }) => {
    const { t } = useTranslation('common', { keyPrefix: 'consumableDateWidget' });
    const ctx = useLocaleCtx();
    const [dateLabelKey, setDateLabelKey] = useState(null);
    const dateFormatOptions  = {
        // weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        timeZoneName: 'short'
    };
    const consumableDateStatusToi18nKeyMap = {
        noDate: 'comingSoon',
        noReleased: 'releaseAt',
        released: 'releasedAt'
    }
    useEffect(() => {
        const i18nKey = consumableDateStatusToi18nKeyMap[consumableDateStatus];
        if(i18nKey){
            setDateLabelKey(i18nKey);
        }
    },[consumableDateStatus])
    return (
        dateLabelKey ?
            <div className='consumable-date-widget-main-container' {...props}>
                <Typography {...labelProps}>
                    {`${textTransform('title',t(dateLabelKey))}`}
                </Typography>
                {isValidDate(releaseDate) ?
                    <Typography {...dateProps}>
                        {releaseDate.toLocaleDateString(ctx.localeCode, dateFormatOptions)}
                    </Typography>
                    :
                    null
                }
            </div>
            :
            null
    )
}

export default ConsumableDateWidget