import { textTransform, textTruncate } from '../../aux/aux';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core'
import CustomChip from '../CustomChip/CustomChip'
import './contentSystemCompatibilityLabel.css'

const ContentSystemCompatibilityLabel = ({ isVersioned, isCompatible }) => {
    const theme = useTheme();
    const TEXT_TRUNCATE_LENGTH_MEDIUM = 20;
    const TEXT_TRUNCATE_END = '...';
    const { t } = useTranslation('common', { keyPrefix: 'contentSystemCompatibilityLabel' });
    const styles = {
        noCompatible:{
            color: theme.palette.grey[400],
            fontStyle: 'italic'
        }
    }
    return (
        isCompatible ?
                isVersioned ?
                        <CustomChip 
                            color={theme.palette.primary.light}
                            size={'small'}
                            label={textTransform('title', textTruncate(t('designedFor'), TEXT_TRUNCATE_LENGTH_MEDIUM, TEXT_TRUNCATE_END))}
                        />
                    :
                        null
            :
                <Typography variant='body2' style={styles.noCompatible}>
                    {textTransform('title', textTruncate(t('noCompatible'), TEXT_TRUNCATE_LENGTH_MEDIUM, TEXT_TRUNCATE_END))}
                </Typography>
    )
}

export default ContentSystemCompatibilityLabel