import { useState, useEffect, useCallback, useRef, Suspense } from "react";
import { useTranslation } from 'react-i18next';
import { createTheme } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
// Stripe
// Allows Stripe to prevent fraudulent behaviour
import "@stripe/stripe-js"
// Helpers
import { sessionVariables, onSessionUpdate, setVisitorLocaleAndCurrencyAndCountry, hasUserSessionTokens, isUserLogged, clearUserSessionVariables, getReliableLocale, setVisitorFallbackLocaleAndCurrencyAndCountry } from "./aux/sessionHelpers";
import { LocaleProvider } from "./customContext/localeProvider";
import { CountryAndCurrencyProvider } from "./customContext/countryAndCurrencyProvider";
import { DisplayablePromoCodeProvider } from "./customContext/displayablePromoCodeProvider"
// Aux components
import { loadScript, textTransform } from "./aux/aux";
import InjectAxiosInterceptors from "./components/InjectAxiosInterceptors/InjectAxiosInterceptors";
import RouterLocationUpdate from "./components/RouterLocationUpdate/RouterLocationUpdate";
import LoadingPopUp from "./components/LoadingPopUp/LoadingPopUp";
// Pages
import Landing from "./pages/landing/Landing";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import LogAndSignIn from "./pages/logAndSignIn/LogAndSignIn";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import Content from "./pages/content/Content";
import UserProfile from "./pages/userProfile/UserProfile";
import MyContent from "./pages/myContent/MyContent";
import Classroom from "./pages/classroom/Classroom";
import SetBillingAddress from "./pages/setBillingAddress/SetBillingAddress";
import Checkout from "./pages/checkout/Checkout";
import CheckSubscriptionStatus from "./pages/checkSubscriptionStatus/CheckSubscriptionStatus";
import PassiveAccountVerification from "./pages/passiveAccountVerification/PassiveAccountVerification";
import Terms from "./pages/terms/Terms";
import Privacy from "./pages/privacy/Privacy";
import Cookies from "./pages/cookies/Cookies";
import EmailSubscriptionListPreferences from "./pages/emailSubscriptionListPreferences/EmailSubscriptionListPreferences";
import Refund from "./pages/refund/Refund";
// Components
// import Header from "./components/Header/Header";
import HeaderSimple from "./components/HeaderSimple/HeaderSimple";
// import HeaderLanding from "./components/HeaderLanding/HeaderLanding";
import HeaderLandingNoLinks from "./components/HeaderLandingNoLinks/HeaderLandingNoLinks";
import MessageDisplay from "./components/MessageDisplay/MessageDisplay";
import LoadingComponent from "./components/LoadingComponent/LoadingComponent";
import CookieConsentCustom from "./components/CookieConsentCustom/CookieConsentCustom";
import BannerAPIConnectivity from "./components/BannerAPIConnectivity/BannerAPIConnectivity";
// Styles
import "./App.css";
import RestorePreviousUserSession from "./components/RestorePreviousUserSession/RestorePreviousUserSession";
import { ScrollableDocumentProvider } from "./customContext/scrollableDocumentProvider";
import HeaderUpdater from "./components/HeaderUpdater/HeaderUpdater";
import { HeaderPropsProvider } from "./customContext/headerPropsProvider";
import Header from "./components/Header/Header";
import { InfoBannerPropsProvider } from "./customContext/infoBannerPropsProvider";
import InfoBannerContainer from "./components/InfoBannerContainer/InfoBannerContainer";
import InfoBannerUpdater from "./components/InfoBannerUpdater/InfoBannerUpdater";
import { UserFavoriteProductProvider } from "./customContext/userFavoriteProductProvider";
import { UserProductNotificationProvider } from "./customContext/userProductNotificationProvider";
import EmailSubscribeSubscriptionList from "./pages/emailSubscribeSubscriptionList/EmailSubscribeSubscriptionList";
import UpdateEmail from "./pages/updateEmail/UpdateEmail";

function App() {
    let theme = createTheme({
        palette:{
            primary: {
                main:'#4355B9',
                lighter: '#DEE0FF',
                darker: '#00105C'
            },
            secondary: {
                main: '#B90063',
                lighter: '#ffD9E2',
                darker: '#3E001D'
            },
            background: {
                dark: '#242424'
            },
            surface: {
                light:{
                    0:'#FEFBFF',
                    1:'#F4F2FB',
                    2:'#EFEDF9',
                    3:'#E9E8F7',
                    4:'#E7E6F6',
                    5:'#E3E3F4'
                },
                dark:{
                    0:'#1B1B1F',
                    1:'#1D1E26',
                    2:'#1E1F2B',
                    3:'#1F2130',
                    4:'#202231',
                    5:'#202231'
                }
            },
            dividerOnDark: '#b3b3b3'
        },
        typography: {
            fontFamily: [
                // 'Roboto',
                // 'raleway',
                // 'Nunito Sans',
                'Noto Sans',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif'
            ].join(','),
            h5:{
                // fontFamily: 'Noto Sans ,Monstserrat, sans-serif',
                fontWeight: "bold",
                // color:"black",
            },
            subtitle2:{
                color:"grey",
            }
        }
    })
    theme = createTheme(theme, {
        palette:{
            ...theme.palette,
            action:{
                ...theme.palette.action,
                disabledOnDark: 'rgba(255, 255, 255, 0.26)',
                disabledBackgroundOnDark: 'rgba(255, 255, 255, 0.12)',
            },
            secondaryLowContrast: theme.palette.augmentColor(
                {
                    main:'#6E4DA1',
                    lighter: '#ECDCFF',
                    darker: '#280057',
                    contrastText:'#fff'
                },
            ),
            terciary: theme.palette.augmentColor(
                {
                    main:'#00B959',
                    lighter: '#D9FFEB',
                    darker: '#003E1E',
                    contrastText:'#fff'
                },
            ),
            qxi: {
                pink: '#EE509C',
                blue: theme.palette.primary.main, // '#00ADC7' replaced by theme.palette.primary
                green: '#00A551',
                yellow:  '#e29100' // '#FAA919' is the original but has been replaced
            },
            text: {
                ...theme.palette.text,
                contrast: theme.palette.background.default //'#F3FBFF'
            },
        }
    })
    const HEADER_CONTAINER_Z_INDEX = 1100;
    const { t, i18n } = useTranslation('common');
    // const updateLocaleCtx = useUpdateLocaleCtx();
    // const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [messageList, setMessageList] = useState({
        msgList:[]
    });
    const isMobile = useMediaQuery(theme.breakpoints.down('xs')) // Smaller than 600px
    const isTablet = useMediaQuery(theme.breakpoints.between('sm','md')) // Between 600 and 1279px 
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg')) // Larger than 1279,... 
    const screenSize = { isMobile: isMobile, isTablet: isTablet, isDesktop: isDesktop }
    const promoContainerRef = useRef(null); 
    
    const styles = {
        appWrapper:{
            backgroundColor: theme.palette.background.default,
        },
        headerContainer: {
            zIndex: HEADER_CONTAINER_Z_INDEX,
        }
    }

    const addMessages = ( messages ) => {
        setMessageList(messageList => ({ msgList: [...messageList.msgList, ...messages] }))
    }

    const setVisitorBasicConfig = async (signal) => {
        try{
            setIsLoading(true);
            await setVisitorLocaleAndCurrencyAndCountry(signal);
        }catch(error){
            console.log(error);
            if(!signal.aborted){
                setVisitorFallbackLocaleAndCurrencyAndCountry();
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    const getReadyForVisitors = async (signal) => {
        try{
            await setVisitorBasicConfig(signal);
            if(!signal.aborted){
                const locale = getReliableLocale();
                await i18n.changeLanguage(locale.localeCode);
            }
        }catch(error){
            // Errors are independently processed on each called async function
        }
    }

    const setAndGreetUser = (user, message=true) => {
        setUser(user);
        if(message){
            if(user !== null){ 
                addMessages(
                        [{message: t('userGreetings', { name: user.basicData.firstName }), severity:'success'}]
                    );
            }else{
                //TODO: Bye User
            }
        }
    }

    useEffect(() => {
        // Set the main domain to allow cross window communication
        // between meeting and main apps. This is deprecated but it still works
        // document.domain = process.env.REACT_APP_DOMAIN;
        const abortController = new AbortController();
        window.addEventListener("storage", onSessionUpdate);
        getReadyForVisitors(abortController.signal);
        return () => {
            abortController.abort();
            window.removeEventListener("storage", onSessionUpdate);
        };
    }, [])
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <InjectAxiosInterceptors setUser={setUser} addMessages={addMessages} />
                <RouterLocationUpdate />
                {isLoading ?
                        <div className="app-loading-container">
                            <LoadingComponent />
                        </div>
                    :
                        <LocaleProvider >
                            <CountryAndCurrencyProvider>
                                <ScrollableDocumentProvider>
                                    <LoadingPopUp />
                                    {hasUserSessionTokens() && !isUserLogged(user) &&
                                        <RestorePreviousUserSession setUser={setUser} addMessages={addMessages} />
                                    }
                                    <HeaderPropsProvider>
                                        <InfoBannerPropsProvider>
                                            <DisplayablePromoCodeProvider>
                                                <div className="App" style={styles.appWrapper}>
                                                    <UserFavoriteProductProvider user={user} setUser={setAndGreetUser}>
                                                        <UserProductNotificationProvider user={user} setUser={setAndGreetUser}>
                                                            <Header user={user} setUser={setAndGreetUser} addMessages={addMessages} ref={promoContainerRef} /> 
                                                            <Switch>
                                                                <Route path='/' exact render={(props) => (<Landing user={user} setUser={setAndGreetUser} promoContainerRef={promoContainerRef}/>)}/>
                                                                <Route path='/content/:productName' exact render={(props) => (<HeaderUpdater newProps={{position:'sticky'}} > <Content productName={props.match.params.productName} user={user} setUser={setUser} addMessages={addMessages} promoContainerRef={promoContainerRef} verMap={{contentPreviewAndFeatures:'1'}}/> </HeaderUpdater>)}/>
                                                                <Route path='/login' exact render={(props) => (<HeaderUpdater newProps={{type:"simple"}}> <LogAndSignIn user={user} setUser={setAndGreetUser} addMessages={addMessages} passiveVerification={process.env.REACT_APP_TARGET_ENV === 'staging'} /> </HeaderUpdater>)}/>
                                                                <Route 
                                                                    path='/password-reset/:resetPasswordToken' 
                                                                    exact 
                                                                    render={(props) => (<HeaderUpdater newProps={{type:"simple"}}> <ResetPassword resetPasswordToken={props.match.params.resetPasswordToken} /> </HeaderUpdater>)}
                                                                />
                                                                <Route 
                                                                    path='/subscription-list/subscribe'
                                                                    exact 
                                                                    render={(props) => (<HeaderUpdater newProps={{type:"simple"}}> <EmailSubscribeSubscriptionList user={user}/> </HeaderUpdater>)}
                                                                />
                                                                <Route 
                                                                    path='/subscription-list'
                                                                    exact 
                                                                    render={(props) => (<HeaderUpdater newProps={{type:"simple"}}> <EmailSubscriptionListPreferences user={user}/> </HeaderUpdater>)}
                                                                />
                                                                <Route 
                                                                    path='/verify-account/:verificationToken' 
                                                                    exact 
                                                                    render={(props) => (<HeaderUpdater newProps={{type:"simple"}}> <PassiveAccountVerification verificationToken={props.match.params.verificationToken} /></HeaderUpdater>)}
                                                                />
                                                                <Route 
                                                                    path='/update-email/:updateEmailToken' 
                                                                    exact 
                                                                    render={(props) => (<HeaderUpdater newProps={{type:"simple"}}> <UpdateEmail updateEmailToken={props.match.params.updateEmailToken} /></HeaderUpdater>)}
                                                                />
                                                                <Route path='/terms-of-use' exact render={(props) => (<Terms user={user} />)}/>
                                                                <Route path='/privacy-policy' exact render={(props) => (<Privacy user={user} />)}/>
                                                                <Route path='/cookies-policy' exact render={(props) => (<Cookies user={user} />)}/>
                                                                <Route path='/refund' exact render={(props) => <Refund user={user} />}/>
                                                                <Route path='/app'>
                                                                    {isUserLogged(user) ?
                                                                            <Switch>
                                                                                <Route path='/app/set-billing-address' render={(props) => (<HeaderUpdater newProps={{type:"simple"}}><SetBillingAddress user={user} /></HeaderUpdater>)} />
                                                                                <Route path='/app/checkout/:checkoutId' exact render={(props) => (<Checkout checkoutId={props.match.params.checkoutId} user={user} setUser={setUser} addMessages={addMessages} />)}/>
                                                                                <Route path='/app/account/:initialItem' exact    render={(props) => {
                                                                                    const VALID_ACCOUNT_ITEMS = ['profile', 'billing', 'general', 'password'];
                                                                                    const initialItem = props.match.params.initialItem;
                                                                                    let component;
                                                                                    if(VALID_ACCOUNT_ITEMS.includes(initialItem)){
                                                                                        component = <UserProfile screenSize={screenSize} user={user} setUser={setUser} initialItem={initialItem}/>
                                                                                    }else{
                                                                                        component = <PageNotFound user={user} />
                                                                                    }
                                                                                    return component
                                                                                }} />
                                                                                <Route path='/app/my-content'>
                                                                                    <MyContent user={user} />
                                                                                </Route>
                                                                                <Route path='/app/learn/:contentProductId' exact render={(props) => (<HeaderUpdater newProps={{position:'relative', backgroundColor: theme.palette.surface.light[2]}}><InfoBannerUpdater newProps={{ style:{position:'fixed'} }}><Classroom contentProductId={props.match.params.contentProductId} user={user} addMessages={addMessages}/></InfoBannerUpdater></HeaderUpdater>)} />
                                                                                <Route path='/app/check-subscription' exact render={(props) => (<HeaderUpdater newProps={{type:"simple"}}><CheckSubscriptionStatus user={user} /></HeaderUpdater>)}/>
                                                                                <Route path='/app/*' render={(props) => (<PageNotFound />)} />
                                                                            </Switch>
                                                                        :
                                                                            <Route path='/app' render={(props) => (<HeaderUpdater newProps={{type:"simple"}}> <LogAndSignIn user={user} setUser={setAndGreetUser} passiveVerification={process.env.REACT_APP_TARGET_ENV === 'staging'} /> </HeaderUpdater>)} />
                                                                    }
                                                                </Route>
                                                                <Route path='*' render={(props) =>{
                                                                    return <PageNotFound user={user} />
                                                                }}/>
                                                            </Switch>
                                                            {messageList.msgList.length > 0 &&
                                                                <MessageDisplay messageList={messageList} setMessageList={setMessageList}/>
                                                            }
                                                            <InfoBannerContainer />
                                                        </UserProductNotificationProvider>
                                                    </UserFavoriteProductProvider>
                                                </div>
                                            </DisplayablePromoCodeProvider>
                                        </InfoBannerPropsProvider>
                                    </HeaderPropsProvider>
                                </ScrollableDocumentProvider>
                            </CountryAndCurrencyProvider>
                        </LocaleProvider>
                }
            </Router>
        </ThemeProvider>
    );
}

export default App;
