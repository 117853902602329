import { useTheme } from "@material-ui/styles"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomChip from "../CustomChip/CustomChip";
import SubscriptionIcon from "../SubscriptionIcon/SubscriptionIcon";

const SelectedProductTypeChip = ({ selectedProductTypeName, size, isContrast=false }) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'selectedProductTypeChip' });
    const [labelKey, setLabelKey] = useState(undefined);
    const colors = {
        new: theme.palette.success.main,
        default: isContrast ? theme.palette.common.white : undefined
    }
    const styles = {
        background: isContrast ? 
            {
                backgroundColor: 'rgba(0,0,0,0.2)',
            }
            :
            {
                backgroundColor: theme.palette.common.white
            }
    }
    useEffect(() => {
        if(selectedProductTypeName){
            let labelKey;
            switch(selectedProductTypeName){
                case 'popular':
                    labelKey = 'favorite'
                    break;
                case 'staff':
                    labelKey = 'selected'
                    break;
                case 'topRated':
                    labelKey = 'favorite'
                    break;
                case 'mostWanted':
                    labelKey = 'mostWanted'
                    break;
                case 'bestSeller':
                    labelKey = 'bestSeller'
                    break;
            }
            setLabelKey(labelKey);
        }
    }, [selectedProductTypeName])
    return (
        labelKey !== undefined ?
            <CustomChip
                size={size || 'small'}
                variant={'outlined'}
                label={t(labelKey)}
                color={colors[labelKey] || colors.default}
                style={styles.background}
            />
            :
            null
    )
}

export default SelectedProductTypeChip