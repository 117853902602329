import React from 'react'
import useDynamicSVGImportFromURL from '../../customHooks/useDynamicSVGImportFromURL'
import useDynamicSVGImport from '../../customHooks/useDynamicSVGImport';
import { useEffect, useState, forwardRef } from 'react';

const DEFAULT_STYLE = {}

const SvgLazyImport = ({localFileName, cdnUri, cdnProvider, LoadingComp=null, FallbackComp=null, style=DEFAULT_STYLE, className}, ref) => {
    const { SvgComponent, isLoading:isImportLoading, error:isImportError } = useDynamicSVGImportFromURL(cdnUri, cdnProvider, ref, style, className);
    const { SvgComponent:LocalSvgComponent, isLoading:isLocalLoading, error:isLocalError } = useDynamicSVGImport(localFileName, ref, style, className);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(isImportLoading === true || isLocalLoading === true)
    },[isImportLoading, isLocalLoading])

    useEffect(() => {
        setError(isImportError || isLocalError)
    },[isImportError, isLocalError])

    return(
        error ?
            FallbackComp
            :
            isLoading ? 
                LoadingComp
                :
                LocalSvgComponent || SvgComponent
    )

}

export default forwardRef(SvgLazyImport)