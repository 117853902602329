import { Button, Typography } from '@material-ui/core';
import Logo from "../../img/logo-alpha.svg";
import LogoNeg from "../../img/logo-alpha-neg.svg";
import { useTranslation } from 'react-i18next';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import bird from '../../lotties/bird.json';
import './refreshBrowserError.css'
import { useEffect, useRef } from 'react';

const RefreshBrowserError = () => {
    const { t } = useTranslation('refreshBrowserError');
    const animationContainerRef = useRef(null);
    const animationRef = useRef(null);
    const styles = {
        title:{
            fontWeight: 'bold'
        },
        disabledText: {
            color: 'rgba(0, 0, 0, 0.38)'
        }
    }

    useEffect(() => {
        if(animationContainerRef.current){
            animationRef.current = lottie.loadAnimation({
                container: animationContainerRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: bird
            });
            return(() => {animationRef.current.stop(); animationRef.current?.destroy()})
        }
    },[])

    return (
        <div className='refresh-browser-error-wrapper'>
            <div className='refresh-browser-error-main-container'>
                <div className='refresh-browser-error-header-container'>
                    <Logo className='refresh-browser-error-logo' onClick={(e) => window.location.href = '/'}/>
                </div>
                <div className='refresh-browser-error-content-container'>
                    <div ref={animationContainerRef} className="refresh-browser-error-animation-container"/>
                    <Typography variant='h4' style={styles.title}>
                        {t('ups')}
                    </Typography>
                    <Typography variant='body1' align='center'>
                        {t('weHaveLoggedAnUnexpectedError')}
                    </Typography>
                    <Typography variant='body1' align='center'>
                        {t('refreshTheBrowserOrTryLater')}
                    </Typography>
                    <div className="refresh-browser-error-button-container">
                        <Button variant='contained' color='primary' onClick={(e) => window.location.reload()}>
                            {t('refreshBrowser')}
                        </Button>
                    </div>
                </div>
                <div className='refresh-browser-error-footer-container'>
                    <Typography variant= "body2" style={styles.disabledText}>
                        &copy; {`${new Date().getFullYear()} QX Institute`}
                    </Typography>
                </div>
            </div>
            {/* <SmallFooter /> */}
        </div>
    )
}

export default RefreshBrowserError