/**
 * This module contains helper function related to all products
 */

import { getFromSafeObject, getSafeDateOrUndefined, isNotEmptyObject, isStringifyJSON, isValidDate, toMillisecondsFromTimescale } from "./aux";

/**
 * 
 * @param {*} productName 
 * @param {*} productType 
 * @param {string} subproductType
 * @returns {string} the client path to the given productName
 */
function getShareableProductURLPath(productName, productType, subproductType='', fullPath=false, includeSubproductType=false){
    let productURLPath
    if(fullPath){
        const domain = window.location.hostname;
        const protocol = window.location.protocol;
        productURLPath = `${protocol}//${domain}`
    }
    switch(productType){
        case 'content':
            const productPath = `/${productType}${includeSubproductType && subproductType ? `/${subproductType}`:''}`;
            productURLPath += `${productPath}/${productName}`
            break;
    }
    return productURLPath
}

/** 
 * 05/25 NO TESTED
 */
function getFilteredProductImageIndexOrUndefined(imageArray=[], imageFilterArray=[]){
    const parseValue = (value) => {
        let result;
        if(!isNaN(parseInt(value))){
            result = Number(value);
        }else if(isStringifyJSON(value)){
            result = JSON.parse(value);
        }else if(value.toLowerCase === 'null'){
            result = null;
        }else{
            result = value
        }
        return result;
    }
    const filterArray = imageFilterArray.map(imageFilter => imageFilter.trim().split(' '));
    let selectedIndex;
    let marks = 0;
    for(let i = 0; i < imageArray.length; i++){
        let image = imageArray[0];
        for(let f = 0; f < filterArray.length; f++){
            let [filter, op, value] = filterArray[f];
            if(image.hasOwnProperty(filter)){
                value = parseValue(value);
                if(value instanceof Array){
                    let imageValue = image[filter];
                    if(isStringifyJSON(imageValue)){
                        imageValue = JSON.parse(imageValue);
                        let intersection = value.filter( elem => imageValue.includes(elem));
                        if(intersection.length === value.filter){
                            marks++
                        }
                    }
                }else{
                    let found = 0;
                    switch(op){
                        case '=':
                            if(image[filter] == value){
                                found = 1;
                            };
                            break;
                        case '>':
                            if(image[filter] > value){
                                found = 1;
                            };
                            break;
                        case '>=':
                            if(image[filter] >= value){
                                found = 1;
                            };
                            break;
                        case '<':
                            if(image[filter] < value){
                                found = 1;
                            };
                            break;
                        case '<=':
                            if(image[filter] <= value){
                                found = 1;
                            };
                            break;
                    }
                    marks += found;
                }
            }
        };
        if(marks === filterArray.length){
            selectedIndex = i;
            break;
        }else{
            marks = 0;
        }
    }
    return selectedIndex;
}

const getFilteredTempConsumptionOptionCategoryMapToUserSubscription = (altProductConsumptionOptionMap, user) => {
    const subscriptionTypeKey = getSubscriptionProductTypeName();
    const subscriptionUpgradeTypeKey = getSubscriptionUpgradeProductTypeName();
    let filteredAltProductConsumptionOptionMap;
    if(user && isNotEmptyObject(user.subscription) && isNotEmptyObject(altProductConsumptionOptionMap)){
        filteredAltProductConsumptionOptionMap = {};
        const subscriptionTypeConsumptionOption = altProductConsumptionOptionMap[subscriptionTypeKey];
        if(isNotEmptyObject(subscriptionTypeConsumptionOption)){
            if(!user.subscription.isActive){
                filteredAltProductConsumptionOptionMap[subscriptionTypeKey] = subscriptionTypeConsumptionOption
            }else{
                // Filter subscriptionUpgradeConsumptionOptions
                // leaving typeUpgrade from the current user subscription productId 
                const subscriptionUpgradeTypeConsumptionOption = altProductConsumptionOptionMap[subscriptionUpgradeTypeKey];
                if(isNotEmptyObject(subscriptionUpgradeTypeConsumptionOption) && user.subscription.subscriptionType.hasOwnProperty('level')){
                    const userSubscriptionTypeLevel = user.subscription.subscriptionType.level;
                    const {   
                        productConsumptionOptionArray,
                        productConsumptionOptionDefaultIndex
                     } = subscriptionUpgradeTypeConsumptionOption;
                    let filteredProductConsumptionOptionArray = [];
                    let filteredProductConsumptionOptionBestPriceIndex;
                    let filteredProductConsumptionOptionDefaultIndex;
                    const defaultProductConsumptionOption = productConsumptionOptionArray[productConsumptionOptionDefaultIndex];
                    const defaultTerm = getFromSafeObject(defaultProductConsumptionOption,'typeProduct.toSubscriptionProduct.typeProduct.term');
                    let bestFinalAmount;
                    for(let i = 0; i < productConsumptionOptionArray.length; i++){
                        const productConsumptionOption = productConsumptionOptionArray[i];
                        const optionToTypeLevel = getFromSafeObject(productConsumptionOption, 'typeProduct.toSubscriptionProduct.typeProduct.type.level')
                        const isSubscriptionTypeUpgrade = isSubscriptionTypeLevelGreaterThan(optionToTypeLevel, userSubscriptionTypeLevel) > 0
                        if(isSubscriptionTypeUpgrade){
                            const userSubscriptionProductId = user.subscription.subscriptionProductId;
                            const optionFromSubscriptionProductId = getFromSafeObject(productConsumptionOption, 'typeProduct.fromSubscriptionProductId');
                            if(optionFromSubscriptionProductId === userSubscriptionProductId){
                                const finalAmount = getFromSafeObject(productConsumptionOption,'consumptionDetails.details.purchasabilityDetails.details.amount.finalAmount');
                                if(bestFinalAmount == undefined || bestFinalAmount > finalAmount){
                                    bestFinalAmount = finalAmount;
                                    filteredProductConsumptionOptionBestPriceIndex = filteredProductConsumptionOptionArray.length;
                                }
                                if(!filteredProductConsumptionOptionDefaultIndex && isNotEmptyObject(defaultTerm)){
                                    const optionTerm = getFromSafeObject(productConsumptionOption,'typeProduct.toSubscriptionProduct.typeProduct.term');
                                    if(defaultTerm.name === optionTerm.name){
                                        filteredProductConsumptionOptionDefaultIndex = filteredProductConsumptionOptionArray.length;
                                    }
                                }
                                filteredProductConsumptionOptionArray.push(productConsumptionOption);
                            }else{
                                continue;
                            }
                        }else{
                            break;
                        }
                    }
                    if(filteredProductConsumptionOptionArray.length > 0){
                        filteredAltProductConsumptionOptionMap[subscriptionUpgradeTypeKey] = {
                            productConsumptionOptionArray: filteredProductConsumptionOptionArray,
                            productConsumptionOptionBestPriceIndex: filteredProductConsumptionOptionBestPriceIndex,
                            productConsumptionOptionDefaultIndex: filteredProductConsumptionOptionDefaultIndex
                        }
                    }
                }
            }
        }
    }else{
        filteredAltProductConsumptionOptionMap = altProductConsumptionOptionMap;
    }
    return filteredAltProductConsumptionOptionMap
}

const MIN_PRODUCT_AVAIL_UNIT_TO_NOTIFY = 10;

const isProductScarce = (availUnits) => {
    return Number.isFinite(availUnits) && availUnits <= MIN_PRODUCT_AVAIL_UNIT_TO_NOTIFY
}

const getProductConsumableDateStatus = (releaseDate) => {
    const now = new Date();
    let consumableDateStatus;
    if(isValidDate(releaseDate)){
        consumableDateStatus = releaseDate.getTime() >= now.getTime() ? 'noReleased' : 'released';
    }else if(releaseDate === null){
        consumableDateStatus = 'noDate';
    }
    return consumableDateStatus
}

const isProductConsumable = (isPurchasable, isSubscriptable) => {
    return isPurchasable === true || isSubscriptable === true
}

/**
 *  
 * @param {*} productConsumptionDetails 
 * @returns 
 */
const getProductConsumptionStatusDetails = (productConsumptionDetails) => {

    const statusDetails = {
        productId: productConsumptionDetails.productId,
        quantity: productConsumptionDetails.quantity,
        currencyId: productConsumptionDetails.currencyId,
        date: productConsumptionDetails.details.requestedDate,
        isConsumable:false,
        isReleased:false,
        isScarce: isProductScarce(productConsumptionDetails.availUnits),
        details:{
            consumabilityStatus: undefined, //noUnitsLeft, noPurchasableOrSubscriptable, noConsumableDate, noAvailEdition, unknown, subscriptable, purchasable, purchasableOrSubscriptable
            consumableDateStatus: undefined, //noDate, noReleased, released,
            releaseDate: productConsumptionDetails.releaseDate,
            availUnits: productConsumptionDetails.availUnits,
            isPurchasable: productConsumptionDetails.isPurchasable,
            isSubscriptable: productConsumptionDetails.isSubscriptable,
            subscriptability: productConsumptionDetails.details.subscriptabilityDetails.details.subscriptability,
        }
    }
    const releaseDate = productConsumptionDetails.releaseDate === null ? productConsumptionDetails.releaseDate : getSafeDateOrUndefined(productConsumptionDetails.releaseDate);
    statusDetails.details.consumableDateStatus = getProductConsumableDateStatus(releaseDate);
    statusDetails.isReleased = statusDetails.details.consumableDateStatus === 'released'
    if(statusDetails.details.consumableDateStatus){
        statusDetails.isConsumable = isProductConsumable(productConsumptionDetails.isPurchasable || productConsumptionDetails.isSubscriptable)
        if(statusDetails.isConsumable){
            if(productConsumptionDetails.isPurchasable && productConsumptionDetails.isSubscriptable){
                statusDetails.details.consumabilityStatus = 'purchasableOrSubscriptable';
            }else if(productConsumptionDetails.isPurchasable){
                statusDetails.details.consumabilityStatus = 'purchasable';
            }else{
                statusDetails.details.consumabilityStatus = 'subscriptable';
            }
        }else{
            if(productConsumptionDetails.availUnits != null && productConsumptionDetails.availUnits <= 0){
                statusDetails.details.consumabilityStatus = 'noUnitsLeft';
            }else if(productConsumptionDetails.availUnits === undefined){
                statusDetails.details.consumabilityStatus = 'noAvailEdition'
            }else{
                statusDetails.details.consumabilityStatus = 'unknown'
            }
        }
    }else{
        statusDetails.details.consumabilityStatus = 'noConsumableDate';
    }
    return statusDetails;
}

const NUM_MONTH_PRODUCT_IS_NEW_AFTER_RELEASED = 3;
const isProductNew = (releaseDate) => {
    let isNew = false;
    const date = getSafeDateOrUndefined(releaseDate);
    if(date){
        const now = new Date();
        isNew = date.getTime() - now.getTime() >= (toMillisecondsFromTimescale('day', 30 * NUM_MONTH_PRODUCT_IS_NEW_AFTER_RELEASED) * -1);
    }
    return isNew;
}

const isProductSubscriptableBySubscriptionLevel = (subscriptionTypeLevel, productSubscriptabilityTypeLevel) => {
    return isSubscriptionTypeLevelGreaterThan(subscriptionTypeLevel, productSubscriptabilityTypeLevel) >= 0;
}

/**
 * Compares A against B
 *  is A lower than B = < 0
 *  is equal = 0
 *  is A higher than B = > 0
 * @param {*} ATypeLevel 
 * @param {*} BTypeLevel 
 * @returns 
 */
const isSubscriptionTypeLevelGreaterThan = ( ATypeLevel, BTypeLevel) => {
    if(Number.isFinite(ATypeLevel) && Number.isFinite(BTypeLevel)){
        return ATypeLevel - BTypeLevel
    }else{
        throw new Error('Cannot get max subscription type level. Wrong type for subscription type level')
    }
}

const getSubscriptionProductTypeName = () => {
    return 'subscription'
}

const getSubscriptionUpgradeProductTypeName = () => {
    return 'subscription_upgrade'
}

const isSubscriptionProductTypeName = (productTypeName) => {
    return typeof productTypeName === 'string' && productTypeName.toLowerCase() === getSubscriptionProductTypeName()
}

const isSubscriptionUpgradeProductTypeName = (productTypeName) => {
    return typeof productTypeName === 'string' && productTypeName.toLowerCase() === getSubscriptionUpgradeProductTypeName()
}

const isSubscriptionOrUpgradeSubscriptionProductTypeName = (productTypeName) => {
    return isSubscriptionProductTypeName(productTypeName) || isSubscriptionUpgradeProductTypeName(productTypeName);
}

export{
    getShareableProductURLPath,
    getFilteredProductImageIndexOrUndefined,
    getFilteredTempConsumptionOptionCategoryMapToUserSubscription,
    getProductConsumptionStatusDetails,
    isProductScarce,
    isProductConsumable,
    getProductConsumableDateStatus,
    isProductNew,
    isProductSubscriptableBySubscriptionLevel,
    getSubscriptionProductTypeName,
    getSubscriptionUpgradeProductTypeName,
    isSubscriptionProductTypeName,
    isSubscriptionUpgradeProductTypeName,
    isSubscriptionOrUpgradeSubscriptionProductTypeName
}